/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var bottomPosition = $('.nav-primary').offset().top + $('.nav-primary').outerHeight() + $('header.banner').outerHeight() - window.innerHeight,
            menuState = 0;


        $('.menu-btn').on('click', function() {
          menuState = 1;
          $('.nav-primary').fadeIn();
          $('.menu-btn').hide();
          $('.close-menu-btn').show();
          $('.page-header').addClass('blur');
          $('.wrap').addClass('blur');
          $('body>.widget_text').addClass('blur');

          if ( $(window).width() > 728 ) {
            $('.header-search form label').css('visibility','hidden');
          }

        });


        $('.close-menu-btn').on('click', function() {
          menuState = 0;
          $('.nav-primary').fadeOut();
          $('.menu-btn').show();
          $('.close-menu-btn').hide();
          $('.page-header').removeClass('blur');
          $('.wrap').removeClass('blur');
          $('body>.widget_text').removeClass('blur');

          if ( $(window).width() > 728 ) {
            $('.header-search form label').css('visibility','visible');
          }

        });



        $(window).bind('scroll', function() {

            if($(window).scrollTop() >= bottomPosition && menuState === 1 ) {

              $(window).scrollTop( bottomPosition );

            }

        });



        // Gallery
        if (typeof pixGalleryImages === 'undefined') {

        } else {

          var currentImage = 0,
              galleryData  = JSON.parse( pixGalleryImages ),
              galleryCount = galleryData.length;



              document.addEventListener('touchstart', handleTouchStart, false);
              document.addEventListener('touchmove', handleTouchMove, false);

              var xDown = null;
              var yDown = null;

              function handleTouchStart(evt) {
                xDown = evt.touches[0].clientX;
                yDown = evt.touches[0].clientY;
              };

              function handleTouchMove(evt) {
                if ( ! xDown || ! yDown ) {
                    return;
                }

                var xUp = evt.touches[0].clientX;
                var yUp = evt.touches[0].clientY;

                var xDiff = xDown - xUp;
                var yDiff = yDown - yUp;

                if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
                    if ( xDiff > 0 ) {
                        /* left swipe */
                        console.log('swipe left');
                        galleryNext();
                    } else {
                        /* right swipe */
                        console.log('swipe right');
                        galleryPrev();
                    }
                } else {
                    if ( yDiff > 0 ) {
                        /* up swipe */
                        console.log('swipe up');
                    } else {
                        /* down swipe */
                        console.log('swipe down');
                    }
                }
                /* reset values */
                xDown = null;
                yDown = null;
              };


              function galleryPrev() {

                  if ( currentImage > 0 ) {
                    currentImage--;
                    $('.gallery-prev').show();
                    $('.gallery-next').show();
                  }

                  if ( currentImage === 0 ) {
                    $('.gallery-prev').hide();
                  }

                  $('.gallery-image img').fadeOut(100, function() {
                    $('.gallery-image img').fadeIn(100).attr('src', galleryData[ currentImage ].large );
                  });

                  $('.gallery-caption').fadeOut(100, function() {
                    $('.gallery-caption').fadeIn(100).html( galleryData[ currentImage ].caption );
                  });

              }


              function galleryNext() {

                if ( currentImage < (galleryCount - 1) ) {
                  currentImage++;
                  $('.gallery-prev').show();
                  $('.gallery-next').show();
                }

                if ( currentImage === (galleryCount - 1) ) {
                  $('.gallery-next').hide();
                }

                $('.gallery-image img').fadeOut(100, function() {
                  $('.gallery-image img').fadeIn(100).attr('src', galleryData[ currentImage ].large );
                });

                $('.gallery-caption').fadeOut(100, function() {
                  $('.gallery-caption').fadeIn(100).html( galleryData[ currentImage ].caption );
                });

              }




          // Open button click
          $('.pix-gallery-open').on('click', function() {
            $('.gallery-container').fadeIn();

            // $('.page-header').addClass('blur');
            // $('.wrap').addClass('blur');
            // $('body>.widget_text').addClass('blur');
            $('body').addClass('noscroll');
          });



          // Close button click
          $('.gallery-header-close').on('click', function() {
            $('.gallery-container').fadeOut();

            // $('.page-header').removeClass('blur');
            // $('.wrap').removeClass('blur');
            // $('body>.widget_text').removeClass('blur');
            $('body').removeClass('noscroll');
          });


          // Preview button click
          $('.gallery-prev').on('click', function() {
            galleryPrev();

          });

          // Next button click
          $('.gallery-next').on('click', function() {
            galleryNext();
          });

        }

        // scroll to fixed
        $(document).ready(function() {

          $('.last-sidebar-item').sticky({
            topSpacing:20,
            bottomSpacing: $('.content-info').outerHeight(true)
          });

        });





            function watchAds() {
            
              jQuery(".ad-container").each(function(i, elAds){
                var elem = $( elAds );
                //console.log("watch: " + elem.outerHeight(true), i, elem.context.offsetHeight, elem );

                  if (elem.context.offsetHeight > 90) { // 150 //  && $(this).css('display') !== 'none'
                    // $(this).show();
                    //$(this).css('display','block');
                    elem.css('visibility','visible');
                    // elem.css('opacity','1');
                    elem.css('position','relative');
                    //console.log('display banner');
                  } else {
                    // elem.attr('style', '');
                    // elem.css('opacity','0');
                    elem.css('height','auto');
                    elem.css('visibility','hidden');
                    elem.css('position','absolute');
                    //$(this).css('display','none');
                    // $(this).hide();
                    //console.log('hide banner');
                  }

              });


            }

            // ======================================

            setTimeout(function() {
              //console.log('watch settime start');
              watchAds();
              //console.log('watch settime end');
            }, 1100);
            //
            setTimeout(function() {
              //console.log('watch 2 settime start');
              watchAds();
              //console.log('watch 2 settime end');
            }, 2010);

            setTimeout(function() {
              //console.log('watch 3 settime start');
              watchAds();
              //console.log('watch 3 settime end');
            }, 5020);

            setTimeout(function() {
              //console.log('watch 4 settime start');
              watchAds();
              //console.log('watch 4 settime end');
            }, 10020);


            // =======================================





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
